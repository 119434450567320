/*------------------------------------------------------------------
Table of content
-------------------
1. Start Header Area
2. slider area start
3. features area css start
4. hot deals area css start
5. product
6. categories area
7. testimonial
8. blog
9. shop grid view
10. shop list view
11. product details
12. checkout
13. cart
14. wishlist
15. my account
16. compare
17. login register
18. contact us
19. banner
20. modal
21. sidebar
22. footer
-------------------------------------------------------------------*/
/*------ Typography Style Start ------*/
body {
  color: #555555;
  line-height: 1.7;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

a {
  transition: 0.4s;
}

a:hover, a:focus {
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 8px;
}

p:last-child {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Josefin Sans", sans-serif;
  color: #222222;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

strong,
b {
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  transition: 0.4s;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn,
button {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: none;
  transition: 0.4s;
  background-color: transparent;
}

.btn:active, .btn:focus,
button:active,
button:focus {
  outline: none;
  box-shadow: none;
}

input,
textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.form-control:focus {
  border-color: #ffa073;
  box-shadow: none;
}

::-moz-selection {
  color: #fff;
  background: #ffa073;
}

::selection {
  color: #fff;
  background: #ffa073;
}

::placeholder {
  color: #999;
  font-size: 13px;
  opacity: 1;
}

/* ----tab problem fix css ----*/
.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.tab-content .tab-pane.active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

/* ----tab problem fix css ----*/
/*------- modal fix start -------*/
.modal-dialog {
  max-width: 1000px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .modal-dialog {
    max-width: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .modal-dialog {
    max-width: 700px;
  }
}

.modal {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -99;
}

.modal.show {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  z-index: 99999999;
  -webkit-animation: slideInDown 0.4s forwards;
  animation: slideInDown 0.4s forwards;
}

.modal .modal-header {
  padding: 0 10px;
  border-bottom: none;
}

.modal .modal-header .close {
  color: #222222;
  font-size: 34px;
  opacity: 1;
  display: block;
  position: absolute;
  padding: 0;
  width: 30px;
  height: 30px;
  margin: 0;
  right: 10px;
  top: 10px;
  font-weight: 500;
  z-index: 999;
  line-height: 30px;
  border-radius: 50%;
}

.modal .modal-body {
  padding: 20px 20px 20px 20px;
}

@media only screen and (max-width: 479.98px) {
  .modal .modal-body {
    padding: 10px;
  }
}

/*------- modal fix end -------*/
/* ---- Sick Slider arrow style start ----*/
.slick-slider .slick-slide > div > div {
  vertical-align: middle;
}

.slick-arrow-style button.slick-arrow {
  top: 50%;
  left: 0;
  font-size: 70px;
  color: #777777;
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: 1;
}

.slick-arrow-style button.slick-arrow.slick-next {
  left: auto;
  right: 0;
}

.slick-arrow-style button.slick-arrow:hover {
  color: #ffa073;
}

.slick-arrow-style:hover button.slick-arrow {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  z-index: 1;
}

.slick-arrow-style:hover button.slick-arrow.slick-prev {
  left: -60px;
  right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499.98px), only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .slick-arrow-style:hover button.slick-arrow.slick-prev {
    left: -20px;
  }
}

.slick-arrow-style:hover button.slick-arrow.slick-next {
  left: auto;
  right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499.98px), only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .slick-arrow-style:hover button.slick-arrow.slick-next {
    right: -20px;
  }
}

.slick-arrow-style_hero button.slick-arrow {
  font-size: 60px;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: transparent;
  z-index: 1;
}

.slick-arrow-style_hero button.slick-arrow.slick-next {
  right: 0;
}

.slick-arrow-style_hero button.slick-arrow:hover {
  color: #fff;
  background-color: transparent;
}

.slick-arrow-style_hero:hover button.slick-arrow.slick-next {
  right: 15px;
}

.slick-arrow-style_hero:hover button.slick-arrow.slick-prev {
  left: 15px;
}

.slick-arrow-style__append button.slick-arrow {
  font-size: 30px;
  top: 0;
  right: 0 !important;
  opacity: 1;
  visibility: visible;
  width: 35px;
  height: 35px;
  margin-top: -56px;
  line-height: 42px;
  border-radius: 4px;
  text-align: center;
  background-color: #efefef;
}

.slick-arrow-style__append button.slick-arrow.slick-prev {
  right: 40px !important;
  left: auto !important;
}

.slick-arrow-style__append button.slick-arrow:hover {
  color: #fff;
  background-color: #ffa073;
}

.slick-append {
  background-color: #fff;
  z-index: 2;
  margin-top: -2px;
}

.slick-append button {
  font-size: 30px;
  line-height: 1;
  position: inherit;
  display: inline-block;
  background-color: transparent;
  color: #555555;
}

.slick-append button:hover {
  color: #ffa073;
}

/*--------- slick slider dot style start -------*/
.slick-dot-style ul.slick-dots {
  bottom: 15px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.slick-dot-style ul.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}

.slick-dot-style ul.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dot-style ul.slick-dots li button {
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  display: block;
  text-indent: -5000px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.4s;
  border: 2px solid #777777;
  background-color: transparent;
}

.slick-dot-style ul.slick-dots li.slick-active button {
  border-color: #ffa073;
  background-color: #ffa073;
}

.slick-row-3 .slick-list {
  margin: 0 -3px;
}

.slick-row-3 .slick-list .slick-slide {
  margin: 0 3px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-3 .slick-list {
    margin: 0 -3px !important;
  }
  .slick-sm-row-3 .slick-list .slick-slide {
    margin: 0 3px !important;
  }
}

.slick-row-4 .slick-list {
  margin: 0 -4px;
}

.slick-row-4 .slick-list .slick-slide {
  margin: 0 4px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-4 .slick-list {
    margin: 0 -4px !important;
  }
  .slick-sm-row-4 .slick-list .slick-slide {
    margin: 0 4px !important;
  }
}

.slick-row-5 .slick-list {
  margin: 0 -5px;
}

.slick-row-5 .slick-list .slick-slide {
  margin: 0 5px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-5 .slick-list {
    margin: 0 -5px !important;
  }
  .slick-sm-row-5 .slick-list .slick-slide {
    margin: 0 5px !important;
  }
}

.slick-row-6 .slick-list {
  margin: 0 -6px;
}

.slick-row-6 .slick-list .slick-slide {
  margin: 0 6px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-6 .slick-list {
    margin: 0 -6px !important;
  }
  .slick-sm-row-6 .slick-list .slick-slide {
    margin: 0 6px !important;
  }
}

.slick-row-7 .slick-list {
  margin: 0 -7px;
}

.slick-row-7 .slick-list .slick-slide {
  margin: 0 7px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-7 .slick-list {
    margin: 0 -7px !important;
  }
  .slick-sm-row-7 .slick-list .slick-slide {
    margin: 0 7px !important;
  }
}

.slick-row-8 .slick-list {
  margin: 0 -8px;
}

.slick-row-8 .slick-list .slick-slide {
  margin: 0 8px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-8 .slick-list {
    margin: 0 -8px !important;
  }
  .slick-sm-row-8 .slick-list .slick-slide {
    margin: 0 8px !important;
  }
}

.slick-row-9 .slick-list {
  margin: 0 -9px;
}

.slick-row-9 .slick-list .slick-slide {
  margin: 0 9px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-9 .slick-list {
    margin: 0 -9px !important;
  }
  .slick-sm-row-9 .slick-list .slick-slide {
    margin: 0 9px !important;
  }
}

.slick-row-10 .slick-list {
  margin: 0 -10px;
}

.slick-row-10 .slick-list .slick-slide {
  margin: 0 10px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-10 .slick-list {
    margin: 0 -10px !important;
  }
  .slick-sm-row-10 .slick-list .slick-slide {
    margin: 0 10px !important;
  }
}

.slick-row-11 .slick-list {
  margin: 0 -11px;
}

.slick-row-11 .slick-list .slick-slide {
  margin: 0 11px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-11 .slick-list {
    margin: 0 -11px !important;
  }
  .slick-sm-row-11 .slick-list .slick-slide {
    margin: 0 11px !important;
  }
}

.slick-row-12 .slick-list {
  margin: 0 -12px;
}

.slick-row-12 .slick-list .slick-slide {
  margin: 0 12px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-12 .slick-list {
    margin: 0 -12px !important;
  }
  .slick-sm-row-12 .slick-list .slick-slide {
    margin: 0 12px !important;
  }
}

.slick-row-13 .slick-list {
  margin: 0 -13px;
}

.slick-row-13 .slick-list .slick-slide {
  margin: 0 13px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-13 .slick-list {
    margin: 0 -13px !important;
  }
  .slick-sm-row-13 .slick-list .slick-slide {
    margin: 0 13px !important;
  }
}

.slick-row-14 .slick-list {
  margin: 0 -14px;
}

.slick-row-14 .slick-list .slick-slide {
  margin: 0 14px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-14 .slick-list {
    margin: 0 -14px !important;
  }
  .slick-sm-row-14 .slick-list .slick-slide {
    margin: 0 14px !important;
  }
}

.slick-row-15 .slick-list {
  margin: 0 -15px;
}

.slick-row-15 .slick-list .slick-slide {
  margin: 0 15px;
}

@media only screen and (max-width: 767.98px) {
  .slick-sm-row-15 .slick-list {
    margin: 0 -15px !important;
  }
  .slick-sm-row-15 .slick-list .slick-slide {
    margin: 0 15px !important;
  }
}

.slick-slider .slick-slide > div {
  margin-bottom: 30px;
}

.slick-slider .slick-slide > div:last-child {
  margin-bottom: 0;
}

/* ----scroll to top css start ----*/
.scroll-top {
  bottom: 50px;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 50px;
  z-index: 9999;
  transition: 0.4s;
  border-radius: 50%;
  background-color: #ffa073;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767.98px) {
  .scroll-top {
    display: none;
  }
}

.scroll-top i {
  line-height: 50px;
  color: #fff;
  font-size: 25px;
}

.scroll-top.not-visible {
  bottom: -50px;
  visibility: hidden;
  opacity: 0;
}

.scroll-top:hover {
  background-color: #222222;
}

/* ----scroll to top css end ----*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row-0 > [class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.row-1 {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.row-1 > [class*="col"] {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.row-2 {
  margin-left: -1px;
  margin-right: -1px;
}

.row-2 > [class*="col"] {
  padding-left: 1px;
  padding-right: 1px;
}

.row-3 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.row-3 > [class*="col"] {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.row-4 {
  margin-left: -2px;
  margin-right: -2px;
}

.row-4 > [class*="col"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.row-5 > [class*="col"] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.row-6 {
  margin-left: -3px;
  margin-right: -3px;
}

.row-6 > [class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}

.row-7 {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.row-7 > [class*="col"] {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.row-8 {
  margin-left: -4px;
  margin-right: -4px;
}

.row-8 > [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row-9 {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.row-9 > [class*="col"] {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.row-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-10 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row-11 {
  margin-left: -5.5px;
  margin-right: -5.5px;
}

.row-11 > [class*="col"] {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.row-12 {
  margin-left: -6px;
  margin-right: -6px;
}

.row-12 > [class*="col"] {
  padding-left: 6px;
  padding-right: 6px;
}

.row-13 {
  margin-left: -6.5px;
  margin-right: -6.5px;
}

.row-13 > [class*="col"] {
  padding-left: 6.5px;
  padding-right: 6.5px;
}

.row-14 {
  margin-left: -7px;
  margin-right: -7px;
}

.row-14 > [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row-15 > [class*="col"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.row-16 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-16 > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.row-17 {
  margin-left: -8.5px;
  margin-right: -8.5px;
}

.row-17 > [class*="col"] {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.row-18 {
  margin-left: -9px;
  margin-right: -9px;
}

.row-18 > [class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

.row-19 {
  margin-left: -9.5px;
  margin-right: -9.5px;
}

.row-19 > [class*="col"] {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.row-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-20 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-21 {
  margin-left: -10.5px;
  margin-right: -10.5px;
}

.row-21 > [class*="col"] {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.row-22 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-22 > [class*="col"] {
  padding-left: 11px;
  padding-right: 11px;
}

.row-23 {
  margin-left: -11.5px;
  margin-right: -11.5px;
}

.row-23 > [class*="col"] {
  padding-left: 11.5px;
  padding-right: 11.5px;
}

.row-24 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-24 > [class*="col"] {
  padding-left: 12px;
  padding-right: 12px;
}

.row-25 {
  margin-left: -12.5px;
  margin-right: -12.5px;
}

.row-25 > [class*="col"] {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.row-26 {
  margin-left: -13px;
  margin-right: -13px;
}

.row-26 > [class*="col"] {
  padding-left: 13px;
  padding-right: 13px;
}

.row-27 {
  margin-left: -13.5px;
  margin-right: -13.5px;
}

.row-27 > [class*="col"] {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.row-28 {
  margin-left: -14px;
  margin-right: -14px;
}

.row-28 > [class*="col"] {
  padding-left: 14px;
  padding-right: 14px;
}

.row-29 {
  margin-left: -14.5px;
  margin-right: -14.5px;
}

.row-29 > [class*="col"] {
  padding-left: 14.5px;
  padding-right: 14.5px;
}

.row-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.row-30 > [class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

/*------- short classes start -------*/
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 767.98px) {
  .section-padding {
    padding-top: 63px;
    padding-bottom: 63px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mtn-20 {
  margin-top: -20px;
}

.mbn-30 {
  margin-bottom: -30px;
}

.mtn-30 {
  margin-top: -30px;
}

.ptb-30 {
  padding: 30px 0;
}

.plr-100 {
  padding: 0 100px;
}

/*------- short classes end -------*/
/*----------- header top area start -----------*/
@media only screen and (min-width: 1600px) {
  .header-wide {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) {
  .header-wide .container {
    max-width: 100%;
  }
}

.header-top {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}

.header-top-settings ul li {
  color: #222222;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  margin-left: 40px;
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.header-top-settings ul li:before {
  top: 50%;
  left: 0;
  width: 1px;
  height: 15px;
  content: "";
  position: absolute;
  background-color: #efefef;
  transform: translateY(-50%);
}

.header-top-settings ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.header-top-settings ul li:first-child:before {
  display: none;
}

.header-top-settings ul li .dropdown-list {
  top: 100%;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: 0.4s;
  padding: 15px 15px 20px;
  width: 150px;
  z-index: 9;
  text-align: left;
  background-color: #fff;
  pointer-events: none;
  border: 1px solid #efefef;
}

.header-top-settings ul li .dropdown-list li {
  margin-left: 0;
  padding: 0;
}

.header-top-settings ul li .dropdown-list li a {
  color: #555555;
  font-size: 13px;
  display: block;
  padding: 5px 0 3px;
  text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list li a:hover {
  color: #ffa073;
}

.header-top-settings ul li .dropdown-list li a img {
  vertical-align: inherit;
}

.header-top-settings ul li .dropdown-list li:before {
  display: none;
}

.header-top-settings ul li:hover .dropdown-list {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  transform: translateY(0);
}

.header-top-settings ul li p {
  cursor: auto;
}

.header-top-settings ul li p a {
  color: #222222;
  padding-left: 3px;
}

.header-top-settings ul li p a:hover {
  color: #ffa073;
}

.welcome-message p {
  color: #222222;
  font-size: 12px;
  line-height: 1;
}

.welcome-message p a {
  color: #053399;
  padding-left: 16px;
  text-decoration: underline;
}

.welcome-message p a:hover {
  color: #ffa073;
}

/*----------- header top area end -----------*/
/*---------- header mini cart start ----------*/
.header-configure-area {
  padding-right: 10px;
}

.header-configure-area ul li {
  display: inline-block;
  margin-left: 25px;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1499.98px) {
  .header-configure-area ul li {
    margin-left: 14px;
  }
}

.header-configure-area ul li a {
  font-size: 26px;
  line-height: 1;
  color: #222222;
  position: relative;
}

.header-configure-area ul li a .notification {
  top: -8px;
  right: -6px;
  position: absolute;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  background-color: #053399;
}

.header-configure-area ul li a:hover {
  color: #ffa073;
}

.header-configure-area ul li:first-child {
  margin-left: 0;
}

.header-configure-area ul li.user-hover {
  position: relative;
}

.header-configure-area ul li.user-hover .dropdown-list {
  position: absolute;
  top: 100%;
  right: 0;
  width: 150px;
  padding: 20px;
  background-color: #fff;
  z-index: 11;
  transform: translateY(30px);
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #efefef;
}

.header-configure-area ul li.user-hover .dropdown-list li {
  display: block;
  margin-left: 0;
}

.header-configure-area ul li.user-hover .dropdown-list li a {
  color: #555555;
  font-size: 13px;
  line-height: 1;
  display: block;
  padding: 8px 0;
  text-transform: capitalize;
}

.header-configure-area ul li.user-hover .dropdown-list li a:hover {
  color: #ffa073;
}

.header-configure-area ul li.user-hover:hover .dropdown-list {
  opacity: 1;
  visibility: visible;
  transform: none;
}

/*----------- header minicart area end -----------*/
/*------- header search area start -------*/
.header-search-container {
  margin-right: 25px;
  position: relative;
}

.header-search-box {
  position: relative;
}

.header-search-box:hover .header-search-btn {
  color: #ffa073;
}

.header-search-btn {
  font-size: 25px;
  line-height: 1;
  left: 10px;
  top: 50%;
  width: 30px;
  position: absolute;
  transform: translateY(-50%);
}

.header-search-field {
  border: none;
  background-color: #f7f7f7;
  border-radius: 30px;
  padding: 10px 20px 10px 50px;
  width: 100%;
}

.search-trigger {
  font-size: 26px;
  color: #222222;
}

.search-box-open {
  top: 100%;
  right: 0;
  width: 300px;
  position: absolute;
  z-index: 5;
  display: block !important;
}

.search-box-open .header-search-field {
  height: 44px;
  border: 1px solid #ddd;
}

/*------- header search area end -------*/
/*-------- header social link start --------*/
.header-social-link a {
  color: #777777;
  font-size: 17px;
  display: inline-block;
  line-height: 1;
  margin-right: 20px;
}

.header-social-link a:last-child {
  margin-right: 0;
}

.header-social-link a:hover {
  color: #ffa073;
}

/*-------- header social link end --------*/
/*---------- main menu style start ---------*/
.main-menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main-menu ul li {
  position: relative;
}

.main-menu ul li a {
  color: #3c3a3a;
  font-size: 16px;
  padding: 37px 15px;
  font-weight: 500;
  display: block;
  text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .main-menu ul li a {
    padding: 37px 12px;
  }
}

.main-menu ul li a i {
  font-size: 14px;
  padding: 0 3px;
}

.main-menu ul li:first-child a {
  padding-left: 0;
}

.main-menu ul li:hover > a, .main-menu ul li.active > a {
  color: #ffa073;
}

.main-menu ul li ul.dropdown {
  top: calc(100% - 1px);
  left: 0;
  width: 220px;
  position: absolute;
  transition: 0.4s;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  pointer-events: none;
  transform: translateY(20px);
  border: 1px solid #efefef;
}

.main-menu ul li ul.dropdown li {
  margin-right: 0;
  border-right: none;
  flex-basis: 100%;
}

.main-menu ul li ul.dropdown li a {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px dashed #efefef;
  z-index: 1;
}

.main-menu ul li ul.dropdown li a:before {
  width: 6px;
  height: 6px;
  left: 20px;
  top: 50%;
  content: ' ';
  position: absolute;
  background-color: #ffa073;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: -1;
}

.main-menu ul li ul.dropdown li a i {
  float: right;
  padding-top: 5px;
}

.main-menu ul li ul.dropdown li:hover > a {
  color: #ffa073;
  background-color: #fff;
  padding-left: 35px;
}

.main-menu ul li ul.dropdown li:hover > a:before {
  opacity: 1;
  visibility: visible;
}

.main-menu ul li ul.dropdown li:hover > ul.dropdown {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.main-menu ul li ul.dropdown li ul.dropdown {
  top: 100%;
  left: 100%;
  position: absolute;
  width: 250px;
  opacity: 0;
  visibility: hidden;
}

.main-menu ul li ul.dropdown li:last-child a {
  border-bottom: none;
}

.main-menu ul li ul.megamenu {
  width: 100%;
  max-width: 1110px;
  padding: 30px;
  left: 50%;
  transform: translate(-50%, 20px);
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .main-menu ul li ul.megamenu {
    max-width: 100%;
  }
}

.main-menu ul li ul.megamenu li {
  flex-basis: 25%;
}

.main-menu ul li ul.megamenu li.mega-title {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.main-menu ul li ul.megamenu li.mega-title span {
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
}

.main-menu ul li ul.megamenu li.mega-title span:before, .main-menu ul li ul.megamenu li.mega-title span:after {
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 2px;
  content: ' ';
  position: absolute;
  background-color: #ebebeb;
}

.main-menu ul li ul.megamenu li.mega-title span:after {
  width: 50%;
  background-color: #ffa073;
}

.main-menu ul li ul.megamenu li a {
  padding: 6px 0;
  border-bottom: none;
}

.main-menu ul li ul.megamenu li a:before {
  left: 0;
}

.main-menu ul li ul.megamenu li ul li {
  flex-basis: 100%;
}

.main-menu ul li ul.megamenu li:hover > a {
  color: #ffa073;
  padding-left: 15px;
}

.main-menu ul li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  transform: translate(0);
}

.main-menu ul li:hover ul.megamenu {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0);
}

/*---------- main menu style end ---------*/
/*------ sticky menu style start ------*/
.header-transparent {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.sticky.is-sticky {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

.sticky.is-sticky .main-menu li a {
  padding: 20px 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .sticky.is-sticky .main-menu li a {
    padding: 20px 12px;
  }
}

.sticky.is-sticky .main-menu li:first-child a {
  padding-left: 0;
}

.sticky.is-sticky .main-menu li .dropdown li a {
  padding: 10px 20px;
}

.sticky.is-sticky .main-menu li .dropdown li:hover > a {
  padding-left: 35px;
}

.sticky.is-sticky .main-menu li .megamenu li a {
  padding: 6px 0;
}

.sticky.is-sticky .main-menu li .megamenu li:hover > a {
  padding-left: 15px;
}

/*------ sticky menu style end ------*/
/*------- Buttons Style here -------*/
.btn {
  font-size: 14px;
  color: #222222;
  line-height: 1;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 50px;
}

.btn-shop {
  color: #fff;
  font-weight: 500;
  background-color: #ffa073;
  padding: 15px 30px;
}

.btn-shop:hover {
  color: #fff;
  background-color: #222222;
}

.btn-text {
  font-size: 16px;
  position: relative;
  pointer-events: visible;
  text-transform: capitalize;
}

.btn-text:before {
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #222222;
}

.btn-text:hover {
  color: #ffa073;
}

.btn-text:hover:before {
  background-color: #ffa073;
}

.btn-cart {
  background-color: #fff;
  text-transform: capitalize;
  box-shadow: 0 2px 1px 0.5px rgba(0, 0, 0, 0.05);
}

.btn-cart:hover {
  color: #fff;
  background-color: #ffa073;
}

.btn-cart2 {
  height: 40px;
  color: #fff;
  line-height: 40px;
  padding: 0 25px;
  background-color: #ffa073;
}

.btn-cart2:hover {
  color: #fff;
  background-color: #222222;
}

.btn-sqr {
  color: #fff;
  font-size: 15px;
  border-radius: 0;
  padding: 14px 25px;
  background-color: #ffa073;
}

.btn-sqr:hover {
  color: #fff;
  background-color: #222222;
}

.btn-hero {
  color: #222222;
  font-size: 16px;
  padding: 14px 30px;
  background-color: #fff;
  margin-top: 38px;
}

@media only screen and (max-width: 575.98px) {
  .btn-hero {
    margin-top: 25px;
  }
}

.btn-hero:hover {
  color: #fff;
  background-color: #222222;
}

/*----- mobile menu start -----*/
.mobile-header {
  padding: 15px 0;
}

.mobile-header-top {
  border-bottom: 1px solid #efefef;
}

.mobile-header-top .header-top-settings {
  float: none;
}

.mobile-logo {
  max-width: 140px;
  width: 100%;
}

.mobile-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-main-header .mobile-menu-toggler {
  display: flex;
  align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
  line-height: 1;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
  font-size: 25px;
  line-height: 1;
  color: #222222;
  position: relative;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a:hover {
  color: #ffa073;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
  font-size: 13px;
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  right: -4px;
  top: -4px;
  position: absolute;
  background-color: #ffa073;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
  margin-left: 25px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span {
  width: 25px;
  height: 2px;
  display: block;
  transition: 0.4s;
  background-color: #222222;
  transform-origin: left;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(2), .mobile-main-header .mobile-menu-toggler .mobile-menu-btn span:nth-child(3) {
  margin-top: 5px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span {
  background-color: #ffa073;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span:nth-child(1), .mobile-main-header .mobile-menu-toggler .mobile-menu-btn:hover span:nth-child(3) {
  width: 20px;
}

.mobile-navigation {
  overflow: hidden;
  max-height: 250px;
}

.mobile-navigation nav {
  height: 100%;
}

.mobile-menu {
  margin-top: 30px;
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
  .mobile-menu {
    margin-top: 15px;
  }
}

.mobile-menu li > a {
  font-size: 15px;
  color: #222222;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.mobile-menu li > a:hover {
  color: #ffa073;
}

.mobile-menu li ul li {
  border: none;
}

.mobile-menu li ul li a {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 0 8px;
}

.mobile-menu li.menu-item-has-children {
  display: block;
  position: relative;
}

.mobile-menu li.menu-item-has-children .dropdown {
  padding-left: 15px;
}

.mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #222222;
  transition: all 250ms ease-out;
}

.mobile-menu li.menu-item-has-children .menu-expand i:before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #222222;
  transition: 0.4s;
}

.mobile-menu li.menu-item-has-children.active > .menu-expand i:before {
  transform: rotate(0);
}

/*----- mobile menu end -----*/
/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
  margin-top: auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 479.98px) {
  .offcanvas-widget-area {
    padding-bottom: 0;
  }
}

.off-canvas-contact-widget li {
  color: #555555;
  font-size: 15px;
  margin-bottom: 5px;
}

.off-canvas-contact-widget li i {
  width: 20px;
}

.off-canvas-contact-widget li a {
  color: #555555;
}

.off-canvas-contact-widget li a:hover {
  color: #ffa073;
}

.off-canvas-social-widget {
  margin-top: 20px;
}

.off-canvas-social-widget a {
  color: #555555;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
}

.off-canvas-social-widget a:hover {
  color: #ffa073;
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: 9999;
}

.off-canvas-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
  transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  cursor: url("../img/icon/cancel.png"), auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
  background-color: #fff;
  width: 300px;
  padding: 20px;
  height: 100%;
  position: relative;
  transform: translateX(calc(-100% - 50px));
  transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
  .off-canvas-wrapper .off-canvas-inner-content {
    width: 260px;
    padding: 15px;
  }
}

.off-canvas-wrapper .btn-close-off-canvas {
  top: 0;
  left: 100%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  transition: 0.4s;
  background-color: #ffa073;
}

.off-canvas-wrapper .btn-close-off-canvas i {
  font-size: 40px;
  transform: rotate(0);
  transition: 0.4s;
  display: block;
  line-height: 40px;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
  transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  height: 100%;
}

.search-box-offcanvas form {
  position: relative;
}

.search-box-offcanvas form input {
  color: #666;
  font-size: 13px;
  width: 100%;
  height: 40px;
  border: none;
  padding: 0 40px 0 10px;
  background-color: #f2f2f2;
}

.search-box-offcanvas form .search-btn {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 20px;
  color: #222222;
  position: absolute;
}

.search-box-offcanvas form .search-btn:hover {
  color: #ffa073;
}

/*------- mobile top bar settings start -------*/
.mobile-settings {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #efefef;
}

.mobile-settings li {
  margin-bottom: 5px;
}

.mobile-settings .nav {
  flex-direction: column;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle {
  font-size: 14px;
  color: #555555;
  cursor: pointer;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle i {
  font-size: 12px;
  padding-left: 5px;
  vertical-align: middle;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:hover {
  color: #ffa073;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:after {
  display: none;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu {
  padding: 0;
  border-color: #efefef;
  transform: translateY(30px);
  transition: 0.4s;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu.show {
  transform: translateY(0);
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu .dropdown-item {
  font-size: 13px;
  color: #555555;
  padding: 6px 15px;
}

/*------- mobile top bar settings end -------*/
/*-------- off canvas mini cart start --------*/
.minicart-inner {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  transition: 0.4s;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}

.minicart-inner .offcanvas-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: url("../img/icon/cancel.png"), auto;
}

.minicart-inner.show {
  opacity: 1;
  visibility: visible;
}

.minicart-inner.show .minicart-inner-content {
  transform: none;
}

.minicart-inner .minicart-close {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #ffa073;
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  top: 0;
  right: 375px;
  position: absolute;
}

.minicart-inner .minicart-close i {
  display: block;
  line-height: 50px;
  transition: 0.6s;
}

.minicart-inner .minicart-close:hover i {
  transform: rotate(180deg);
}

.minicart-inner .minicart-inner-content {
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 375px;
  position: absolute;
  background-color: #fff;
  transform: translateX(calc(100% + 50px));
  transition: 0.4s;
  padding: 50px 20px;
}

.minicart-content-box {
  overflow: auto;
  height: 100%;
  padding-right: 30px;
  margin-right: -15px;
}

.minicart-item-wrapper {
  border-bottom: 1px solid #efefef;
}

.minicart-item-wrapper ul li {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.minicart-item-wrapper ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.minicart-item {
  display: flex;
  flex-wrap: wrap;
}

.minicart-thumb {
  max-width: 85px;
  flex-basis: 85px;
}

.minicart-content {
  padding: 0 10px;
  max-width: calc(100% - 115px);
  flex-basis: calc(100% - 115px);
}

.minicart-content .product-name {
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.minicart-content .product-name a {
  color: #222222;
}

.minicart-content .product-name a:hover {
  color: #ffa073;
}

.minicart-content .cart-quantity {
  font-size: 12px;
  color: #555555;
  line-height: 1;
}

.minicart-content .cart-quantity strong {
  font-size: 16px;
  font-weight: 400;
  vertical-align: text-bottom;
}

.minicart-content .cart-price {
  color: #ffa073;
  font-size: 14px;
  line-height: 1;
}

.minicart-remove {
  max-width: 30px;
  flex-basis: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  color: #222222;
}

.minicart-remove:hover {
  color: #ffa073;
}

.minicart-pricing-box {
  border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-top: 15px;
}

.minicart-pricing-box li {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.minicart-pricing-box li span {
  font-size: 14px;
  color: #222222;
  text-transform: capitalize;
}

.minicart-pricing-box li span strong {
  color: #ffa073;
  font-weight: 400;
}

.minicart-pricing-box li.total span {
  font-size: 16px;
}

.minicart-pricing-box li:last-child {
  margin-bottom: 0;
}

.minicart-button a {
  color: #222222;
  font-size: 14px;
  display: block;
  font-weight: 500;
  line-height: 1;
  padding: 17px 0;
  background-color: #f3f3f3;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 10px;
}

.minicart-button a i {
  padding-right: 5px;
}

.minicart-button a:hover {
  color: #fff;
  letter-spacing: 1.1px;
  background-color: #ffa073;
}

.minicart-button a:last-child {
  margin-bottom: 0;
}

/*-------- off canvas mini cart end --------*/
/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
  height: 680px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .hero-slider-item {
    height: 400px;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-item {
    height: 350px;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-content {
    padding-right: 140px;
  }
}

@media only screen and (max-width: 479.98px) {
  .hero-slider-content {
    padding-right: 0;
  }
}

.hero-slider-content .slide-title {
  font-size: 60px;
  font-weight: normal;
  color: #fff;
}

.hero-slider-content .slide-title span {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .hero-slider-content .slide-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .hero-slider-content .slide-title {
    font-size: 40px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-content .slide-title {
    font-size: 30px;
  }
}

.hero-slider-content .slide-desc {
  color: #fff;
  font-weight: 400;
  line-height: 1.3;
  padding-top: 20px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 575.98px) {
  .hero-slider-content .slide-desc {
    padding-top: 10px;
  }
}

.hero-overlay {
  position: relative;
}

.hero-overlay:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

@media only screen and (max-width: 767.98px) {
  .hero-overlay:before {
    display: block;
  }
}

/*------ hero slider area css end ------*/
/*------ service policy start ------*/
.policy-block {
  border-bottom: 1px solid #efefef;
}

.policy-item {
  display: flex;
  margin-top: 30px;
  margin-bottom: -3px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .policy-item {
    display: block;
    text-align: center;
  }
}

.policy-icon {
  font-size: 35px;
  color: #222222;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 5px solid #fff4f2;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .policy-icon {
    margin: auto;
    margin-bottom: 20px;
  }
}

.policy-content {
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .policy-content {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .policy-content {
    padding-left: 0;
  }
}

.policy-content p {
  color: #777;
  font-size: 14px;
  line-height: 1;
}

.policy-title {
  line-height: 1;
  font-weight: 400;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.policy-title:before {
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  content: '';
  position: absolute;
  background-color: #4d5e7c;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .policy-title:before {
    left: 50%;
    transform: translateX(-50%);
  }
}

/*------ service policy end ------*/
/*------ banner statistics style start ------*/
/* 1.0 banner statistics */
.banner-statistics {
  overflow: hidden;
}

.banner-statistics img {
  width: 100%;
}

.banner-statistics:hover img {
  transform: scale(1.05);
}

/*------ banner statistics style end ------*/
/*------ section title start ------*/
.section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  position: relative;
}

@media only screen and (max-width: 767.98px) {
  .section-header {
    margin-top: -10px;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 479.98px) {
  .section-header {
    display: block;
    text-align: center;
  }
}

.section-header:before {
  top: 50%;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #cacaca;
  position: absolute;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479.98px) {
  .section-header:before {
    display: none;
  }
}

.section-header .section-title {
  margin-bottom: 0;
  margin-top: 0;
  background-color: #fff;
  padding-right: 20px;
  position: relative;
}

.section-header .product-tab-menu {
  padding-right: 100px;
  padding-left: 20px;
  background-color: #fff;
  position: relative;
}

@media only screen and (max-width: 767.98px) {
  .section-header .product-tab-menu {
    padding-right: 0;
  }
}

@media only screen and (max-width: 479.98px) {
  .section-header .product-tab-menu {
    padding-left: 0;
    margin-top: 20px;
  }
}

.section-title {
  margin-top: -4px;
  margin-bottom: 37px;
}

.section-title .title {
  text-transform: capitalize;
}

@media only screen and (max-width: 767.98px) {
  .section-title .title {
    font-size: 24px;
  }
}

.section-title .sub-title {
  font-size: 14px;
  padding-top: 6px;
  color: #555555;
}

@keyframes shine {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 100%;
  }
}

.section-title-append {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 18px;
  line-height: 1;
}

.section-title-append:after {
  top: 50%;
  right: 0;
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: #efefef;
  transform: translateY(-50%);
  z-index: 1;
  margin-top: -3px;
}

.section-title-append h3 {
  display: inline-block;
  line-height: 1;
  text-transform: capitalize;
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding-right: 10px;
}

/*------ section title end ------*/
/*------- common style css start -------*/
.bg-gray {
  background-color: #f3f5f6;
}

.black-bg {
  background-color: #222222;
}

/*-------- Custom Checkbox Style start --------*/
.custom-checkbox.custom-control,
.custom-radio.custom-control {
  min-height: auto;
}

.custom-checkbox label,
.custom-checkbox .custom-control-label,
.custom-radio label,
.custom-radio .custom-control-label {
  font-size: 14px;
  line-height: 1;
  padding-left: 10px;
  font-weight: 400;
  display: block;
  cursor: pointer;
}

.custom-checkbox label:before,
.custom-checkbox .custom-control-label:before,
.custom-radio label:before,
.custom-radio .custom-control-label:before {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 0;
  height: 15px;
  top: 0;
  width: 15px;
}

.custom-checkbox label:after,
.custom-checkbox .custom-control-label:after,
.custom-radio label:after,
.custom-radio .custom-control-label:after {
  background-size: 10px 10px;
  height: 15px;
  top: 0;
}

.custom-checkbox label a,
.custom-checkbox .custom-control-label a,
.custom-radio label a,
.custom-radio .custom-control-label a {
  color: #222222;
  font-weight: 500;
}

.custom-checkbox label a:hover,
.custom-checkbox .custom-control-label a:hover,
.custom-radio label a:hover,
.custom-radio .custom-control-label a:hover {
  color: #ffa073;
}

.custom-checkbox input,
.custom-checkbox .custom-control-input,
.custom-radio input,
.custom-radio .custom-control-input {
  outline: none;
}

.custom-checkbox input:focus ~ .custom-control-label:before,
.custom-checkbox input:focus label:before,
.custom-checkbox .custom-control-input:focus ~ .custom-control-label:before,
.custom-checkbox .custom-control-input:focus label:before,
.custom-radio input:focus ~ .custom-control-label:before,
.custom-radio input:focus label:before,
.custom-radio .custom-control-input:focus ~ .custom-control-label:before,
.custom-radio .custom-control-input:focus label:before {
  box-shadow: none;
}

.custom-checkbox input:checked ~ .custom-control-label:before,
.custom-checkbox input:checked label:before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-checkbox .custom-control-input:checked label:before,
.custom-radio input:checked ~ .custom-control-label:before,
.custom-radio input:checked label:before,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-radio .custom-control-input:checked label:before {
  background-color: #ffa073;
  border-color: #ffa073;
}

/*-------- Custom Checkbox Style end --------*/
/*------- Custom Radio Button Style start -------*/
.custom-radio label:before,
.custom-radio .custom-control-label:before {
  border-radius: 50%;
  height: 14px;
  width: 14px;
}

.custom-radio label::after,
.custom-radio .custom-control-label::after {
  background-size: 10px 10px;
  left: -22px;
  top: 2px;
  height: 10px;
  width: 10px;
}

/*------- Custom Radio Button Style end -------*/
/*----- product tab menu start -----*/
@media only screen and (max-width: 767.98px) {
  .featured-area {
    padding-top: 68px;
  }
}

.product-tab-menu ul li {
  margin-right: 35px;
}

@media only screen and (max-width: 479.98px) {
  .product-tab-menu ul li {
    margin-right: 15px;
  }
}

.product-tab-menu ul li:last-child {
  margin-right: 0;
}

.product-tab-menu ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #777777;
}

.product-tab-menu ul li a:hover, .product-tab-menu ul li a.active {
  color: #222222;
}

.tab-content .tab-pane.show.active .product-item {
  animation-name: zoomIn;
  animation-duration: 1s;
}

/*----- product tab menu start -----*/
/*----- brand logo style start -----*/
.brand-logo-carousel {
  padding: 30px 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.brand-item img {
  margin: auto;
}

/*----- brand logo style end -----*/
/*------ special product style start ------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .special-product-thumb {
    margin-top: 3px;
  }
}

.special-content {
  padding-right: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .special-content {
    padding-top: 43px;
    padding-right: 0;
  }
}

.special-content .price-old {
  font-size: 18px;
  color: #555555;
  padding-right: 5px;
}

.special-content .price-regular {
  font-size: 24px;
  color: #ffa073;
}

.special-content p {
  color: #777777;
  padding-top: 22px;
  margin-bottom: 35px;
}

@media only screen and (max-width: 767.98px) {
  .special-content p {
    margin-bottom: 25px;
  }
}

.special-title {
  font-weight: 700;
  padding-bottom: 22px;
}

@media only screen and (max-width: 767.98px) {
  .special-title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 10px;
  }
}

/*------ special product style end ------*/
/*------- common style css end -------*/
/*------- product item start -------*/
.product-item:hover .product-thumb .sec-img, .product-list-item:hover .product-thumb .sec-img {
  opacity: 1;
  visibility: visible;
}

.product-item:hover .product-thumb .pri-img, .product-list-item:hover .product-thumb .pri-img {
  opacity: 0;
  visibility: hidden;
}

.product-item:hover .button-group a, .product-list-item:hover .button-group a {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.product-item:hover .button-group a:last-child, .product-list-item:hover .button-group a:last-child {
  left: 105px;
  transition-delay: 0.5s;
}

.product-item:hover .button-group a:first-child, .product-list-item:hover .button-group a:first-child {
  right: 105px;
  transition-delay: 0.5s;
}

.product-thumb {
  position: relative;
  overflow: hidden;
}

.product-thumb img {
  width: 100%;
}

.product-thumb .sec-img {
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.product-caption {
  padding-top: 27px;
}

.product-caption .product-name {
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 14px;
  font-family: "Poppins", sans-serif;
}

.product-caption .product-name a {
  color: #222222;
  text-transform: capitalize;
}

.product-caption .product-name a:hover {
  color: #ffa073;
}

.color-categories {
  line-height: 1;
}

.color-categories li {
  display: inline-block;
  padding: 1px;
  border: 1px solid #efefef;
  transition: 0.4s;
}

.color-categories li:hover {
  border-color: #ffa073;
}

.color-categories li a {
  height: 15px;
  width: 15px;
  display: block;
}

.c-lightblue {
  background-color: #b0c4de;
}

.c-darktan {
  background-color: #aa9e78;
}

.c-grey {
  background-color: #808080;
}

.c-brown {
  background-color: #964B00;
}

.button-group a {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-size: 28px;
  line-height: 56px;
  color: #222222;
  text-align: center;
  background-color: #fff;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transform: translateY(50px);
}

.button-group a span {
  display: block;
}

.button-group a:hover {
  color: #fff;
  background-color: #ffa073;
}

.button-group a:nth-child(2) {
  z-index: 2;
}

.product-label {
  background: #ffa073;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  top: 10px;
  left: 10px;
  line-height: 1;
  min-width: 50px;
  padding: 6px 12px;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  z-index: 3;
}

.product-label span {
  display: block;
  margin-top: -1px;
}

.ratings {
  color: #f9bd22;
  font-size: 14px;
}

.ratings span {
  margin-right: 3px;
}

.price-box {
  font-size: 15px;
  line-height: 1;
}

.price-regular {
  color: #ffa073;
  font-weight: 500;
}

.price-old {
  color: #999;
  padding-left: 5px;
  font-weight: 300;
}

/*------- product item end -------*/
/*-------- product list item start --------*/
.product-list-item {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767.98px) {
  .product-list-item {
    display: block !important;
  }
}

.product-list-item .product-thumb {
  max-width: 30%;
  flex-basis: 30%;
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
  .product-list-item .product-thumb {
    max-width: 40%;
    flex-basis: 40%;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-list-item .product-thumb {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.product-content-list {
  padding-left: 20px;
  max-width: calc(100% - 30%);
  flex-basis: calc(100% - 30%);
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
  .product-content-list {
    max-width: calc(100% - 40%);
    flex-basis: calc(100% - 40%);
  }
}

@media only screen and (max-width: 767.98px) {
  .product-content-list {
    padding-left: 0;
    max-width: 100%;
    flex-basis: 100%;
    margin-top: 30px;
  }
}

.product-content-list .ratings {
  padding-bottom: 10px;
}

.product-content-list .product-name {
  padding-bottom: 12px;
}

.product-content-list .product-name a {
  color: #222222;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.product-content-list .product-name a:hover {
  color: #ffa073;
}

.product-content-list p {
  padding-top: 15px;
  margin-top: 22px;
  border-top: 1px solid #efefef;
}

.product-content-list .button-group {
  margin-top: 25px;
}

.product-content-list .button-group a {
  opacity: 1;
  visibility: visible;
  position: static;
  transform: translateY(0);
  color: #fff;
  background-color: #222222;
}

.product-content-list .button-group a:hover {
  background-color: #ffa073;
}

/*-------- product list item end --------*/
/*------ group list item start ------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .group-product-banner {
    margin-bottom: 80px;
  }
}

.group-item {
  display: flex;
}

.group-item-thumb {
  max-width: 80px;
  flex-basis: 80px;
}

.group-item-desc {
  position: relative;
  padding-left: 10px;
  max-width: calc(100% - 80px);
  flex-basis: calc(100% - 80px);
}

.group-item-desc .group-product-name {
  line-height: 1.4;
  font-weight: 400;
  text-transform: capitalize;
  padding-bottom: 18px;
  font-family: "Poppins", sans-serif;
}

.group-item-desc .group-product-name a {
  color: #222222;
}

.group-item-desc .group-product-name a:hover {
  color: #ffa073;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .categories-group-wrapper {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767.98px) {
  .categories-group-wrapper {
    margin-bottom: 60px;
  }
}

.row [class*="col-"]:last-child .categories-group-wrapper {
  margin-bottom: 0;
}

.group-list-item-wrapper .slick-slider .slick-slide > div {
  margin-bottom: 20px;
}

.group-list-item-wrapper .slick-slider .slick-slide > div:last-child {
  margin-bottom: 0;
}

/*------ group list item end ------*/
/*----- testimonial area start -----*/
.testimonial-thumb {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  border: 2px solid #ffa073;
  margin-bottom: 35px;
}

.testimonial-content-wrapper {
  max-width: 910px;
  margin: auto;
  margin-top: 7px;
  margin-bottom: -7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .testimonial-content-wrapper {
    max-width: 750px;
  }
}

.testimonial-content {
  text-align: center;
}

.testimonial-content p {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  color: #222222;
  font-style: italic;
  font-family: "Josefin Sans", sans-serif;
}

@media only screen and (max-width: 767.98px) {
  .testimonial-content p {
    font-size: 18px;
  }
}

.testimonial-content span {
  color: #222222;
  font-size: 14px;
}

.testimonial-author {
  font-weight: 500;
  line-height: 1;
  color: #222222;
  text-transform: uppercase;
  margin: 30px 0 7px;
  font-family: "Poppins", sans-serif;
}

/*----- testimonial area end -----*/
/*----- breadcrumb style css start -----*/
.breadcrumb-area {
  background-color: #f5f5f5;
}

.breadcrumb-wrap {
  padding: 45px 0;
  text-align: center;
}

@media only screen and (max-width: 767.98px) {
  .breadcrumb-wrap {
    padding: 35px 0;
  }
}

.breadcrumb-wrap .breadcrumb {
  background: transparent;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item {
  line-height: 1;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item a {
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item a:hover {
  color: #ffa073;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item:before {
  color: #7e7e7e;
  content: "/";
  font-size: 12px;
  margin: 0 5px;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}

.breadcrumb-wrap .breadcrumb .breadcrumb-item.active {
  color: #ffa073;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
  text-transform: capitalize;
}

/*----- breadcrumb style css end -----*/
/*----- shop Sidebar start -----*/
.sidebar-wrapper .sidebar-single:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .sidebar-wrapper {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 767.98px) {
  .sidebar-wrapper {
    margin-top: 56px;
  }
}

.sidebar-single {
  margin-bottom: 35px;
}

.sidebar-single .sidebar-title {
  display: inline-block;
  position: relative;
  line-height: 1;
  margin-top: -3px;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.sidebar-single .sidebar-title:before {
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -10px;
  content: " ";
  position: absolute;
  background-color: #efefef;
}

/*------ pricing filter slider start ------*/
.price-range-wrap {
  padding-top: 8px;
}

.price-range-wrap .price-range {
  border-radius: 0;
  margin-right: 13px;
  margin-bottom: 20px;
}

.price-range-wrap .price-range.ui-widget-content {
  border: none;
  background: #eeeeee;
  height: 6px;
  border-radius: 20px;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-range {
  background-color: #ffa073;
  border-radius: 0;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle {
  border: none;
  background-color: #fff;
  height: 12px;
  width: 12px;
  outline: none;
  cursor: ew-resize;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle:before {
  top: 50%;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: #ffa073;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.price-range-wrap .range-slider {
  color: #222222;
  margin-top: 30px;
}

.price-range-wrap .range-slider .price-input label {
  color: #222222;
  margin-bottom: 0;
}

.price-range-wrap .range-slider .price-input input {
  color: #555555;
  border: none;
  outline: none;
  max-width: 80px;
  pointer-events: none;
}

.price-range-wrap .range-slider button.filter-btn {
  border: none;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-transform: uppercase;
  padding: 4px 15px;
  border-radius: 3px;
  background-color: #ebebeb;
}

.price-range-wrap .range-slider button.filter-btn:hover {
  color: #fff;
  background-color: #ffa073;
}

/*------ pricing filter slider end ------*/
.categories-list li {
  color: #555555;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.categories-list li:last-child {
  margin-bottom: 0;
}

.categories-list li:hover {
  color: #ffa073;
}

/*------ shop categories area start ------*/
.shop-categories {
  margin-top: -4px;
}

.shop-categories li a {
  color: #555555;
  display: block;
  font-weight: 300;
  line-height: 1;
  padding: 10px 0;
  text-transform: capitalize;
}

.shop-categories li:first-child a {
  padding-top: 0;
}

.shop-categories li:last-child a {
  padding-bottom: 0;
}

.shop-categories li:hover a {
  color: #ffa073;
}

/*------ shop categories area end ------*/
/*----- shop Sidebar end -----*/
/*------ pagination area style start ------*/
.paginatoin-area {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #efefef;
}

.paginatoin-area .pagination-box {
  display: flex;
  justify-content: center;
}

.paginatoin-area .pagination-box li {
  margin-right: 5px;
  display: inline-block;
}

.paginatoin-area .pagination-box li:last-child {
  margin-right: 0;
}

.paginatoin-area .pagination-box li a {
  color: #222222;
  height: 36px;
  width: 36px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.paginatoin-area .pagination-box li a i {
  font-size: 30px;
  line-height: 36px;
}

.paginatoin-area .pagination-box li a:hover {
  color: #fff;
  border-color: #ffa073;
  background-color: #ffa073;
}

.paginatoin-area .pagination-box li.active a {
  color: #fff;
  background-color: #ffa073;
}

/*------ pagination area style end ------*/
/*------- blog item start -------*/
.latest-blog-area {
  margin-bottom: -6px;
}

.blog-post-item:hover .blog-thumb img {
  transform: scale(1.1);
}

.blog-thumb {
  overflow: hidden;
}

.blog-thumb img {
  width: 100%;
}

.blog-content {
  padding-top: 30px;
}

.blog-content .read-more {
  color: #555555;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: underline;
}

.blog-content .read-more:hover {
  color: #ffa073;
}

.blog-title {
  line-height: 1.2;
  text-transform: capitalize;
}

@media only screen and (max-width: 479.98px) {
  .blog-title {
    font-size: 21px;
  }
}

.blog-title a {
  color: #222222;
  display: block;
}

.blog-title a:hover {
  color: #ffa073;
}

.blog-desc {
  color: #777777;
  padding: 7px 0 12px;
  margin-bottom: 0;
}

.blog-meta {
  padding-bottom: 16px;
}

.blog-meta p {
  color: #ffa073;
  font-style: italic;
  font-weight: 30;
  font-family: "Josefin Sans", sans-serif;
  line-height: 1;
  text-decoration: underline;
}

/*------- blog item end -------*/
/*----- blog sidebar start -------*/
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .blog-sidebar-wrapper {
    margin-bottom: -5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .blog-sidebar-wrapper {
    margin-top: 94px;
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 767.98px) {
  .blog-sidebar-wrapper {
    margin-top: 56px;
    margin-bottom: -5px;
  }
}

.blog-sidebar-wrapper .blog-sidebar:last-child {
  margin-bottom: 0;
}

.blog-sidebar {
  margin-bottom: 30px;
}

.blog-sidebar .title {
  display: inline-block;
  line-height: 1;
  margin-top: -2px;
  margin-bottom: 40px;
  position: relative;
}

.blog-sidebar .title:before {
  width: 100%;
  height: 1px;
  bottom: -10px;
  left: 0;
  background-color: #efefef;
  content: "";
  position: absolute;
}

.blog-sidebar .search-field {
  width: calc(100% - 50px);
  border: 1px solid #ccc;
  padding: 0 10px;
  color: #555555;
  height: 50px;
  float: left;
  text-transform: capitalize;
}

.blog-sidebar .search-btn {
  width: 50px;
  height: 50px;
  margin-left: -4px;
  text-align: center;
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  transition: 0.4s;
}

.blog-sidebar .search-btn i {
  font-size: 20px;
  line-height: 50px;
  color: #222222;
  transition: 0.4s;
}

.blog-sidebar .search-btn:hover {
  background-color: #ffa073;
  border-color: #ffa073;
}

.blog-sidebar .search-btn:hover i {
  color: #fff;
}

.blog-sidebar .blog-archive {
  margin-top: -10px;
}

.blog-sidebar .blog-archive li {
  line-height: 1;
}

.blog-sidebar .blog-archive li a {
  color: #555555;
  line-height: 1;
  font-weight: 300;
  text-transform: capitalize;
  padding: 10px 0;
  display: inline-block;
}

.blog-sidebar .blog-archive li a:hover {
  color: #ffa073;
}

.blog-sidebar .blog-archive li:last-child a {
  padding-bottom: 0;
}

.blog-sidebar .blog-archive li:first-child a {
  padding-top: 0;
}

.blog-sidebar .blog-tags li {
  display: inline-block;
  margin-bottom: 5px;
}

.blog-sidebar .blog-tags li a {
  color: #222222;
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 6px 15px 4px;
  display: inline-block;
}

.blog-sidebar .blog-tags li a:hover {
  color: #fff;
  background-color: #ffa073;
  border-color: #ffa073;
}

.blog-category {
  margin-top: -10px;
}

.recent-post .recent-post-item:last-child {
  margin-bottom: 0;
}

.recent-post-item {
  margin-bottom: 20px;
  display: flex;
}

.recent-post-item .product-thumb {
  max-width: 70px;
  flex: 1 0 70px;
}

.recent-post-item .recent-post-description {
  padding-left: 10px;
}

.recent-post-item .recent-post-description h6 {
  padding-bottom: 5px;
  text-transform: capitalize;
}

.recent-post-item .recent-post-description h6 a {
  color: #222222;
}

.recent-post-item .recent-post-description h6 a:hover {
  color: #ffa073;
}

.recent-post-item .recent-post-description p {
  font-size: 14px;
  font-weight: 30;
}

/*----- blog sidebar end -------*/
/*------ blog details style start ------*/
.entry-summary {
  padding-top: 15px;
}

blockquote {
  border-left: 5px solid #ffa073;
  margin: 25px 32px 25px 30px;
  background-color: #f8f8f8;
  padding: 15px;
}

.blog-details-post .blog-content .blog-title {
  padding-top: 10px;
}

@media only screen and (max-width: 767.98px) {
  .blog-details-post .blog-content .blog-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479.98px) {
  .blog-details-post .blog-content .blog-title {
    font-size: 18px;
  }
}

.blog-details-post .blog-content .blog-meta {
  padding-top: 15px;
  padding-bottom: 0;
}

.blog-details-post:hover .blog-thumb img {
  transform: none;
}

/*------- blog main content wrapper start --------*/
.blog-post-item .tag-line {
  padding: 10px 0;
  margin-top: 20px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.blog-post-item .tag-line h6 {
  display: inline-block;
  padding-right: 5px;
}

.blog-post-item .tag-line a {
  font-size: 13px;
  color: #222222;
}

.blog-post-item .tag-line a:hover {
  color: #ffa073;
}

.blog-share-link {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .blog-share-link {
    padding-top: 15px;
  }
}

.blog-share-link h6 {
  padding-right: 15px;
}

.blog-share-link .blog-social-icon a {
  width: 36px;
  height: 36px;
  font-size: 15px;
  line-height: 36px;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-share-link .blog-social-icon a.facebook {
  background-color: #3B5999;
}

.blog-share-link .blog-social-icon a.twitter {
  background-color: #1DA1F2;
}

.blog-share-link .blog-social-icon a.pinterest {
  background-color: #CB2028;
}

.blog-share-link .blog-social-icon a.google {
  background-color: #fe6d4c;
}

.blog-share-link .blog-social-icon a:hover.facebook {
  background-color: #2d4474;
}

.blog-share-link .blog-social-icon a:hover.twitter {
  background-color: #0c85d0;
}

.blog-share-link .blog-social-icon a:hover.pinterest {
  background-color: #9f191f;
}

.blog-share-link .blog-social-icon a:hover.google {
  background-color: #fe4419;
}

@media only screen and (max-width: 479.98px) {
  .blog-share-link .blog-social-icon a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    margin-right: 0;
  }
}

/*--- blog main content wrapper end ---*/
/*--- blog comment section start ---*/
.comment-section {
  margin-top: -3px;
}

.comment-section h5 {
  line-height: 1;
  padding-bottom: 15px;
}

.comment-section ul li {
  display: flex;
  padding: 10px 20px;
  margin-bottom: 30px;
  border: 1px solid #efefef;
}

.comment-section ul li:last-child {
  margin-bottom: 5px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li {
    display: block;
  }
}

.comment-section ul li .author-avatar {
  flex-basis: 66px;
  max-height: 62px;
  margin-right: 10px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li .author-avatar {
    flex-basis: 100%;
    max-width: 66px;
    height: 62px;
  }
}

.comment-section ul li.comment-children {
  margin-left: 40px;
}

@media only screen and (max-width: 479.98px) {
  .comment-section ul li.comment-children {
    margin-left: 20px;
  }
}

.comment-section ul li .comment-body {
  flex-basis: 100%;
}

.comment-section ul li .comment-body h5 {
  font-size: 14px;
  padding-bottom: 5px;
  font-family: "Poppins", sans-serif;
}

.comment-section ul li .comment-body .comment-post-date {
  font-size: 14px;
  padding-bottom: 10px;
}

.comment-section ul li .comment-body .reply-btn {
  float: right;
}

.comment-section ul li .comment-body .reply-btn a {
  color: #222222;
  font-size: 12px;
  display: inline-block;
  padding: 4px 15px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #efefef;
}

.comment-section ul li .comment-body .reply-btn a:hover {
  color: #fff !important;
  border-color: #ffa073;
  background-color: #ffa073;
}

/*--- blog comment section end ---*/
/*------ blog comment box start -----*/
.blog-comment-wrapper {
  margin-top: -10px;
}

.blog-comment-wrapper h3 {
  color: #222222;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  padding-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f0f0;
}

.blog-comment-wrapper p {
  margin-bottom: 10px;
  padding-top: 10px;
}

.blog-comment-wrapper .comment-post-box label {
  color: #222222;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize;
}

.blog-comment-wrapper .comment-post-box .coment-field {
  background: #f8f8f8;
  border: none;
  color: #555555;
  padding: 8px 10px;
  width: 100%;
}

@media only screen and (max-width: 767.98px) {
  .blog-comment-wrapper .comment-post-box .coment-field {
    margin-bottom: 20px;
  }
}

.blog-comment-wrapper .comment-post-box textarea {
  height: 130px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background: #f8f8f8;
  border: none;
}

.blog-comment-wrapper .comment-post-box .coment-btn {
  margin-top: 30px;
}

@media only screen and (max-width: 767.98px) {
  .blog-comment-wrapper .comment-post-box .coment-btn {
    margin-top: 10px;
  }
}

/*------- blog comment box end --------*/
/*------ blog details style start ------*/
/*------ shop page style start ------*/
.shop-top-bar {
  margin-bottom: 30px;
}

.top-bar-left {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767.98px) {
  .top-bar-left {
    padding-top: 15px;
    justify-content: space-between;
  }
}

.top-bar-left .product-view-mode {
  margin-right: 60px;
}

@media only screen and (max-width: 479.98px) {
  .top-bar-left .product-view-mode {
    margin-right: 0;
  }
}

.top-bar-left .product-view-mode a {
  color: #b6b6b6;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.top-bar-left .product-view-mode a i {
  font-size: 20px;
  margin-top: 6px;
}

.top-bar-left .product-view-mode a:hover {
  color: #ffa073;
}

.top-bar-left .product-view-mode a.active {
  color: #ffa073;
}

.top-bar-left .product-amount p {
  color: #777;
  font-size: 14px;
  text-transform: capitalize;
}

.top-bar-right {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 767.98px) {
  .top-bar-right {
    justify-content: center;
  }
}

.top-bar-right .product-short {
  display: flex;
  align-items: center;
}

.top-bar-right .product-short p {
  margin-right: 10px;
}

.nice-select {
  height: 36px;
  line-height: 34px;
  width: 200px;
  padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .nice-select {
    width: 170px;
  }
}

@media only screen and (max-width: 479.98px) {
  .nice-select {
    width: 190px;
  }
}

.nice-select.open {
  border-color: #ffa073;
}

.nice-select .list {
  right: 0;
  left: auto;
  width: 100%;
}

.nice-select .option {
  font-size: 13px;
  line-height: 34px;
  min-height: 34px;
  text-transform: capitalize;
}

.nice-select:after {
  height: 6px;
  width: 6px;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

/*----- shop main wrapper end -----*/
/*----- product change view start -----*/
.shop-product-wrap.list-view .product-item {
  display: none;
}

.shop-product-wrap.grid-view .product-item {
  display: block;
  animation-name: zoomIn;
  animation-duration: 1s;
  padding: 0;
}

.shop-product-wrap.grid-view .product-list-item {
  display: none !important;
}

.shop-product-wrap.list-view .product-list-item {
  display: flex;
  animation-name: fadeInRight;
  animation-duration: 1s;
}

.shop-product-wrap.row.list-view .col-md-4 {
  flex: 0 0 100%;
  max-width: 100%;
}

.shop-product-wrap [class*="col-"] {
  margin-bottom: 30px;
}

/*----- product change view end -----*/
/*------ shop page style start ------*/
/*----- product details slider start ----*/
.pro-large-img {
  position: relative;
  cursor: pointer;
}

.pro-large-img img {
  width: 100%;
}

.pro-nav-thumb {
  cursor: pointer;
}

.pro-nav {
  margin-top: 20px;
}

/*----- product details slider end ----*/
/*----- product details content start ----*/
.option-title {
  line-height: 1;
  font-weight: 400;
  margin-right: 10px;
  text-transform: capitalize;
}

.product-details-des .product-name {
  font-weight: 400;
  margin-top: -5px;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 575.98px) {
  .product-details-des .product-name {
    font-size: 20px;
  }
}

.product-details-des .pro-review {
  padding-left: 10px;
}

.product-details-des .pro-review span {
  line-height: 1;
  color: #555555;
}

.product-details-des .price-box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-details-des .customer-rev {
  padding-top: 10px;
}

.product-details-des .customer-rev a {
  color: #222222;
  font-size: 13px;
  text-transform: capitalize;
}

.product-details-des .customer-rev a:hover {
  color: #ffa073;
}

.product-details-des .quantity-cart-box {
  margin-bottom: 20px;
}

.product-details-des .quantity {
  margin-right: 15px;
}

.product-details-des .quantity .pro-qty {
  width: 90px;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 40px;
  float: left;
}

.product-details-des .quantity .pro-qty .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: #555555;
}

.product-details-des .quantity .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.product-details-des .pro-desc {
  margin-bottom: 20px;
}

.product-details-des .availability {
  margin-bottom: 15px;
}

.product-details-des .availability i {
  color: #81ca33;
}

.product-details-des .availability span {
  color: #555555;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  padding-left: 3px;
  text-transform: uppercase;
}

.product-details-des .useful-links {
  display: flex;
  margin-bottom: 20px;
}

.product-details-des .useful-links a {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-right: 20px;
  text-transform: capitalize;
}

.product-details-des .useful-links a:hover {
  color: #ffa073;
}

.product-details-des .useful-links a i {
  font-size: 20px;
  padding-right: 5px;
  vertical-align: middle;
}

.product-details-des .like-icon {
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

.product-details-des .like-icon a {
  color: #fff;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  padding: 0 8px;
  margin-right: 6px;
  border-radius: 3px;
  text-transform: capitalize;
}

@media only screen and (max-width: 479.98px) {
  .product-details-des .like-icon a {
    margin-right: 0;
  }
}

.product-details-des .like-icon a i {
  padding-right: 5px;
}

.product-details-des .like-icon a.facebook {
  background-color: #3B5999;
}

.product-details-des .like-icon a.twitter {
  background-color: #1DA1F2;
}

.product-details-des .like-icon a.pinterest {
  background-color: #CB2028;
}

.product-details-des .like-icon a.google {
  background-color: #fe6d4c;
}

.product-details-des .like-icon a:hover.facebook {
  background-color: #2d4474;
}

.product-details-des .like-icon a:hover.twitter {
  background-color: #0c85d0;
}

.product-details-des .like-icon a:hover.pinterest {
  background-color: #9f191f;
}

.product-details-des .like-icon a:hover.google {
  background-color: #fe4419;
}

.product-details-des .share-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product-details-des .share-icon a {
  color: #555555;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  padding: 0 8px;
  margin-right: 5px;
}

@media only screen and (max-width: 479.98px) {
  .product-details-des .share-icon a {
    padding: 0 5px;
  }
}

.product-details-des .share-icon a:hover {
  color: #ffa073;
}

.product-details-des .color-option {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 18px;
}

.product-details-des .pro-size {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.product-details-des .pro-size .nice-select {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
}

.product-details-des .group-product-table {
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
}

.product-details-des .group-product-table.table-bordered {
  border: none;
}

.product-details-des .group-product-table td {
  width: 33.33%;
  padding: 8px;
  vertical-align: middle;
}

.product-details-des .group-product-table td a {
  color: #555555;
  text-transform: capitalize;
}

.product-details-des .group-product-table td a:hover {
  color: #ffa073;
}

.product-details-des .group-product-table td .pro-qty {
  width: 90px;
  height: 35px;
  border: 1px solid #ddd;
  padding: 0 15px;
  float: left;
}

.product-details-des .group-product-table td .pro-qty .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 31px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #555555;
}

.product-details-des .group-product-table td .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 33px;
  line-height: 33px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

/*------ countdown style end ------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .product-details-des {
    margin-top: 60px;
  }
}

.product-details-des.quick-details {
  margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .product-details-des.quick-details {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-details-des.quick-details {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .product-details-des.quick-details .product-name {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-details-des.quick-details .product-name {
    font-size: 18px;
  }
}

.product-details-des.quick-des p {
  padding-top: 0;
}

/*----- product details content end ----*/
/*----- reviews area start -----*/
.product-review-info .nav.review-tab li a {
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-transform: capitalize;
  padding: 8px 10px;
  border: 1px solid #ffa073;
  border-bottom: none;
  background-color: #ffa073;
}

.product-review-info .nav.review-tab li a:hover, .product-review-info .nav.review-tab li a.active {
  color: #555555;
  background-color: #fff;
  border-color: #ddd;
}

@media only screen and (max-width: 479.98px) {
  .product-review-info .nav.review-tab li a {
    font-size: 14px;
    padding: 8px 7px;
  }
}

.product-review-info .tab-content.reviews-tab {
  border: 1px solid #efefef;
  padding: 15px 20px 20px;
}

.product-review-info .tab-content.reviews-tab .table {
  margin-bottom: 0;
}

.product-review-info .tab-content.reviews-tab .table tr {
  text-transform: capitalize;
}

.product-review-info .tab-content .review-description {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .product-review-info .tab-content .review-description {
    padding: 15px 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .product-review-info .tab-content .review-description {
    display: block;
  }
}

.product-review-info .tab-content .review-description .tab-thumb {
  flex-basis: 300px;
}

@media only screen and (max-width: 767.98px) {
  .product-review-info .tab-content .review-description .tab-thumb {
    flex-basis: 220px;
  }
}

.product-review-info .tab-content .review-description .tab-des {
  padding-left: 40px;
  flex-basis: calc(100% - 300px);
}

@media only screen and (max-width: 767.98px) {
  .product-review-info .tab-content .review-description .tab-des {
    padding-left: 14px;
    flex-basis: calc(100% - 220px);
  }
}

.product-review-info .tab-content .review-description .tab-des h3 {
  color: #ffa073;
  font-size: 20px;
  font-weight: 22px;
  font-weight: 700;
  text-transform: capitalize;
  padding-bottom: 10px;
}

.product-review-info .tab-content .review-description .tab-des ul li {
  font-size: 13px;
  list-style: inherit;
}

.review-form h5 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
  .review-form h5 {
    font-size: 16px;
  }
}

.total-reviews {
  display: flex;
  padding-bottom: 50px;
}

@media only screen and (max-width: 479.98px) {
  .total-reviews {
    display: block;
  }
}

.total-reviews .rev-avatar {
  flex-basis: 60px;
  max-height: 60px;
}

@media only screen and (max-width: 479.98px) {
  .total-reviews .rev-avatar {
    margin-bottom: 20px;
  }
}

.total-reviews .review-box {
  margin-left: 10px;
  border: 1px solid #efefef;
  padding: 10px;
  flex-basis: calc(100% - 70px);
}

@media only screen and (max-width: 479.98px) {
  .total-reviews .review-box {
    margin-left: 0;
  }
}

.total-reviews .review-box .post-author {
  padding-bottom: 10px;
}

.total-reviews .review-box .post-author p {
  font-size: 12px;
  font-style: italic;
  text-transform: capitalize;
}

.total-reviews .review-box .post-author p span {
  font-size: 13px;
}

.total-reviews .review-box p {
  font-style: italic;
}

/*----- reviews area end -----*/
/* Checkout Login Coupon Accordion Start */
.checkout-page-wrapper {
  margin-bottom: -8px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .checkout-page-wrapper {
    margin-bottom: 0;
  }
}

.checkoutaccordion {
  margin-bottom: 46px;
}

.checkoutaccordion h5 {
  background-color: #f7f7f7;
  border-top: 3px solid #ffa073;
  padding: 15px 20px;
  position: relative;
  text-transform: capitalize;
}

.checkoutaccordion h5 span {
  color: #ffa073;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 10px;
}

@media only screen and (max-width: 479.98px) {
  .checkoutaccordion h5 span {
    display: block;
    padding-top: 5px;
    margin-left: 0;
  }
}

.checkoutaccordion h5 span:hover {
  color: #222222;
}

@media only screen and (max-width: 479.98px) {
  .checkoutaccordion h5 {
    font-size: 14px;
  }
}

.checkoutaccordion .card {
  border: none;
  padding: 0;
  transition: 0.4s;
  margin-bottom: 30px;
}

.checkoutaccordion .card:last-child {
  margin-bottom: 0;
}

.checkoutaccordion .card .card-body {
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 20px;
}

.checkoutaccordion .card .card-body .cart-update-option {
  border: none;
  padding: 0;
}

.checkoutaccordion .card .card-body .cart-update-option .apply-coupon-wrapper input {
  padding: 12px 10px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  margin-right: 15px;
}

@media only screen and (max-width: 479.98px) {
  .checkoutaccordion .card .card-body .cart-update-option .apply-coupon-wrapper input {
    margin-bottom: 15px;
  }
}

.checkout-box-wrap .custom-control {
  padding-left: 0;
  margin-left: 1.5rem;
}

.checkout-billing-details-wrap .billing-form-wrap {
  margin-top: -20px;
}

.checkout-title {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767.98px) {
  .checkout-title {
    margin-bottom: 26px;
  }
}

.forget-pwd {
  color: #ffa073;
  font-size: 12px;
  margin-top: -6px;
}

.single-form-row {
  margin-top: 15px;
  display: none;
}

.single-form-row p {
  margin: 0;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .order-summary-details {
    margin-top: 55px;
  }
}

.order-summary-table {
  background-color: #f7f7f7;
  margin-top: 34px;
}

.order-summary-table .table,
.order-summary-table table {
  color: #222222;
  margin-bottom: 0;
  font-size: 15px;
  white-space: nowrap;
}

.order-summary-table .table tr td,
.order-summary-table .table tr th,
.order-summary-table table tr td,
.order-summary-table table tr th {
  font-weight: 500;
  vertical-align: middle;
  padding: 15px 10px;
  border-width: 1px;
}

.order-summary-table .table tr td a,
.order-summary-table .table tr th a,
.order-summary-table table tr td a,
.order-summary-table table tr th a {
  color: #222222;
  font-weight: 400;
}

.order-summary-table .table tr td strong,
.order-summary-table .table tr th strong,
.order-summary-table table tr td strong,
.order-summary-table table tr th strong {
  font-weight: 400;
}

.order-summary-table .table tr td,
.order-summary-table table tr td {
  font-weight: 400;
}

.order-summary-table .shipping-type {
  text-align: left;
}

.order-summary-table .shipping-type li {
  margin-bottom: 5px;
}

.order-summary-table .shipping-type li:last-child {
  margin-bottom: 0;
}

.order-payment-method {
  background-color: #f7f7f7;
  padding: 40px 20px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .order-payment-method {
    padding: 32px 20px 36px;
  }
}

.single-payment-method {
  margin-bottom: 20px;
}

.single-payment-method:last-child {
  margin-bottom: 0;
}

.single-payment-method:first-child .payment-method-details {
  display: block;
}

.single-payment-method label,
.single-payment-method .custom-control-label {
  font-weight: 500;
}

.single-payment-method .paypal-card {
  max-width: 150px;
  height: 50px;
  margin-top: 10px;
  display: block;
}

.single-payment-method .payment-method-details {
  background-color: #f1f1f1;
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  padding: 10px;
  position: relative;
  margin-top: 20px;
  display: none;
}

.single-payment-method .payment-method-details:after {
  content: '';
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f1f1f1;
  bottom: 100%;
}

.single-payment-method .payment-method-details * {
  margin: 0;
}

.summary-footer-area .btn-brand {
  margin-top: 40px;
}

/*------- input box style css start -------*/
.single-input-item {
  margin-top: 20px;
}

.single-input-item label {
  color: #222222;
  text-transform: capitalize;
  font-size: 14px;
}

.single-input-item label.required:after {
  content: '*';
  color: red;
  font-size: 14px;
  margin-left: 3px;
  margin-top: 5px;
}

.single-input-item input,
.single-input-item textarea {
  color: #555555;
  border: 1px solid #ccc;
  padding: 12px 10px;
  width: 100%;
  font-size: 14px;
  background: #f7f7f7;
}

.single-input-item input:active, .single-input-item input:focus,
.single-input-item textarea:active,
.single-input-item textarea:focus {
  border-color: #ffa073;
  background-color: #fff;
}

.single-input-item .nice-select {
  width: 100%;
  border-radius: 0;
  height: 48px;
  border-color: #ccc;
  background-color: #f7f7f7;
}

.single-input-item .nice-select .current {
  color: #555555;
}

.single-input-item .nice-select .list {
  max-height: 200px;
  overflow: auto;
  width: 100%;
}

/*------- input box style css end -------*/
/*--------- Cart Page Wrapper Start ---------*/
.cart-table .table {
  margin: 0;
  white-space: nowrap;
}

.cart-table .table thead {
  background-color: #ffa073;
}

.cart-table .table thead tr th {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 20px;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  border-color: #ffa073;
  border-bottom: 0 solid transparent;
}

.cart-table .table tbody tr td {
  border-color: #ccc;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  color: #222222;
}

.cart-table .table tbody tr td a {
  color: #222222;
  font-weight: 400;
  text-transform: capitalize;
}

.cart-table .table tbody tr td a:hover {
  color: #ffa073;
}

.cart-table .table tbody tr td a.btn {
  color: #fff;
}

.cart-table .table tbody tr td a.btn:hover {
  color: #fff;
}

.cart-table .table tbody tr td a.check-btn {
  color: #fff;
}

.cart-table .table tr .pro-thumbnail,
.cart-table .table tr .pro-price,
.cart-table .table tr .pro-quantity,
.cart-table .table tr .pro-subtotal,
.cart-table .table tr .pro-remove {
  width: 140px;
}

.cart-table .table tr .pro-qty {
  width: 90px;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 15px;
  float: left;
}

.cart-table .table tr .pro-qty .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 38px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #555555;
}

.cart-table .table tr .pro-qty input {
  width: 28px;
  float: left;
  border: none;
  height: 40px;
  line-height: 34px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.cart-update-option {
  border: 1px solid #ccc;
  margin-top: 15px;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px), only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .cart-update-option {
    margin-top: 0;
    border-top: 0 solid transparent;
  }
}

.cart-update-option .apply-coupon-wrapper {
  width: 55%;
}

@media only screen and (max-width: 767.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
  .cart-update-option .apply-coupon-wrapper {
    width: auto;
  }
}

.cart-update-option .apply-coupon-wrapper form {
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.cart-update-option .apply-coupon-wrapper form input {
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 9px 10px;
  outline: none;
  margin-right: 15px;
  width: 100%;
  transition: 0.4s;
}

.cart-update-option .apply-coupon-wrapper form input:focus, .cart-update-option .apply-coupon-wrapper form input:active {
  border-color: #ffa073;
}

@media only screen and (max-width: 767.98px) {
  .cart-update-option .apply-coupon-wrapper button {
    display: block;
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .cart-update-option .cart-update {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767.98px) {
  .cart-update-option .cart-update .btn {
    width: 100%;
  }
}

.cart-calculator-wrapper {
  margin-top: 30px;
  background-color: #f8f8f8;
}

.cart-calculator-wrapper h6 {
  padding: 20px 15px 18px;
  margin-bottom: 0;
}

.cart-calculator-wrapper .cart-calculate-items {
  font-weight: 500;
}

.cart-calculator-wrapper .cart-calculate-items .table {
  margin-bottom: 0;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td {
  color: #222222;
  font-weight: 400;
  padding: 15px 20px;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td:nth-child(2) {
  color: #222222;
  text-align: right;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td.total-amount {
  color: #ffa073;
}

.cart-calculator-wrapper a {
  border-radius: 0;
  text-align: center;
}

/*--------- Cart Page Wrapper end ---------*/
/*------ Start Compare Page Wrapper -----*/
.compare-table .table tbody tr td {
  text-align: center;
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-weight: 500;
  color: #222222;
  margin: 0;
}

@media only screen and (max-width: 767.98px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 115px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
}

@media only screen and (max-width: 767.98px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  font-size: 14px;
  color: #ffa073;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  color: #222222;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #ffa073;
}

.compare-table .table tbody tr td.pro-desc p {
  color: #555555;
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-weight: 400;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: 0.4s;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #e74c3c;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #ffa073;
}

/*------ end Compare Page Wrapper -----*/
/*-------- Start My Account Page Wrapper --------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #efefef;
  border-bottom: none;
  color: #222222;
  display: block;
  font-weight: 500;
  padding: 10px 15px;
  font-family: "Josefin Sans", sans-serif;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #efefef;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #ffa073;
  border-color: #ffa073;
  color: #fff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767.98px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 575.98px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h5 {
  font-weight: 500;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content strong {
  font-weight: 500;
  color: #222222;
}

.myaccount-content .welcome a {
  color: #222222;
}

.myaccount-content .welcome a:hover {
  color: #ffa073;
}

.myaccount-content .welcome strong {
  font-weight: 500;
  color: #ffa073;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  color: #222222;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 400;
  border-bottom: 1px dashed #ccc;
}

.myaccount-table {
  white-space: nowrap;
}

.myaccount-table table th,
.myaccount-table .table th {
  color: #222222;
  padding: 10px;
  font-weight: 400;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #f4f5f7;
  border-top: 3px solid #ffa073;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
  color: #555555;
  padding: 20px;
}

/*-------- Start My Account Page Wrapper --------*/
/*------ Start Login & Register Page ------*/
.login-reg-form-wrap {
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 23px 30px 30px;
}

@media only screen and (max-width: 575.98px) {
  .login-reg-form-wrap {
    padding: 23px 15px 30px;
  }
}

.login-reg-form-wrap form .create-account {
  margin-top: 25px;
}

.login-reg-form-wrap .login-reg-form-meta a {
  color: #ffa073;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .sign-up-form {
    margin-top: 62px;
  }
}

/*------ end Login & Register Page ------*/
/*----- Google map area start -----*/
#google-map {
  height: 500px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  #google-map {
    height: 400px;
  }
}

@media only screen and (max-width: 767.98px) {
  #google-map {
    height: 350px;
  }
}

/*----- Google map area end -----*/
/*------ contact form area start -------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px), only screen and (max-width: 767.98px) {
  .contact-area {
    margin-bottom: -8px;
  }
}

.contact-message h2 {
  color: #222222;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 28px;
  text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
  .contact-message h2 {
    font-size: 18px;
    padding-bottom: 15px;
  }
}

.contact-message form input, .contact-message form textarea {
  width: 100%;
  border: none;
  padding: 10px 10px;
  border-bottom: 3px solid transparent;
  background-color: #f7f7f7;
  margin-bottom: 30px;
}

.contact-message form textarea {
  height: 150px;
}

/*------ contact form area start -------*/
/*------ contact info area start -------*/
.contact-info {
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .contact-info {
    margin-top: 95px;
  }
}

@media only screen and (max-width: 767.98px) {
  .contact-info {
    margin-top: 60px;
  }
}

.contact-info p {
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .contact-info p {
    padding-bottom: 10px;
  }
}

.contact-info ul li {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479.98px) {
  .contact-info ul li {
    align-items: baseline;
  }
}

.contact-info ul li:last-child {
  border-bottom: none;
}

.contact-info ul li i {
  font-size: 18px;
  padding-right: 10px;
}

.contact-info .working-time h6 {
  padding-bottom: 5px;
}

.contact-info .working-time p {
  color: #555555;
  padding-bottom: 0;
}

.contact-info .working-time p span {
  padding-right: 10px;
}

.contact-title {
  line-height: 1;
  padding-bottom: 22px;
}

@media only screen and (max-width: 575.98px) {
  .contact-title {
    font-size: 20px;
    padding-bottom: 8px;
  }
}

/*------ contact info area end -------*/
/*------ newsletter area start ------*/
.newsletter-area {
  padding-top: 48px;
  padding-bottom: 45px;
  border-bottom: 1px solid #2f2f2f;
}

.newsletter-wrapper .title {
  line-height: 1;
  padding-bottom: 24px;
}

.newsletter-wrapper .newsletter-inner {
  max-width: 600px;
  margin: auto;
  display: flex;
}

@media only screen and (max-width: 767.98px) {
  .newsletter-wrapper .newsletter-inner {
    max-width: 450px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 479.98px) {
  .newsletter-wrapper .newsletter-inner {
    display: block;
  }
}

.newsletter-wrapper .newsletter-inner .news-field {
  height: 50px;
  color: #959595;
  width: calc(100% - 172px);
  padding: 0 20px;
  background-color: #262626;
  border-radius: 50px;
  border: 2px solid #3c3c3c;
  margin-right: 20px;
}

@media only screen and (max-width: 479.98px) {
  .newsletter-wrapper .newsletter-inner .news-field {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.newsletter-wrapper .newsletter-subtitle {
  color: #999999;
  padding-top: 20px;
}

.news-btn {
  color: #fff;
  font-size: 16px;
  height: 50px;
  font-weight: 500;
  padding: 0 35px;
  border-radius: 50px;
  background-color: #ffa073;
}

.news-btn:hover {
  background-color: #333333;
}

/*------ newsletter area end ------*/
/*----- footer area start -----*/
.footer-widget-area {
  padding-top: 78px;
  padding-bottom: 70px;
  border-bottom: 1px solid #2f2f2f;
}

@media only screen and (max-width: 767.98px) {
  .footer-widget-area {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}

.widget-title {
  color: #fff;
  line-height: 1;
  padding-bottom: 10px;
}

.usefull-links li a {
  color: #999999;
  font-size: 16px;
  padding: 5px 0;
  display: block;
}

.usefull-links li:hover a {
  padding-left: 10px;
  color: #ffa073;
}

.footer-middle-area {
  padding: 35px 0;
  border-bottom: 1px solid #2f2f2f;
}

.footer-middle-area .address-block {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479.98px) {
  .footer-middle-area .address-block {
    display: block;
  }
}

.footer-middle-area .footer-logo {
  padding-right: 20px;
  border-right: 2px solid #fff;
}

@media only screen and (max-width: 479.98px) {
  .footer-middle-area .footer-logo {
    margin-bottom: 30px;
    border-right: none;
    padding-right: 0;
  }
}

.footer-middle-area .address-info {
  width: 60%;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 479.98px) {
  .footer-middle-area .address-info {
    width: 100%;
    padding-left: 0;
  }
}

.footer-middle-area .address-info i {
  color: #ffa073;
  font-size: 24px;
  line-height: 1;
  padding-right: 15px;
}

.footer-middle-area .address-info p {
  color: #999;
  line-height: 1.4;
}

.footer-middle-area .address-info p span {
  font-weight: 900;
}

.footer-middle-area .address-info p a {
  color: #999;
}

.footer-middle-area .address-info p a:hover {
  color: #ffa073;
}

.footer-middle-area .address-info.email {
  width: 100%;
}

.footer-middle-area .address-info.email span {
  display: block;
}

.footer-bottom-area {
  padding: 25px 0;
}

.copyright {
  color: #999999;
  font-size: 14px;
}

.copyright a {
  color: #ffa073;
}

/*----- footer area end -----*/
