/*------ section title start ------*/
.section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
    position: relative;
    // responsive
    @media #{$sm-device} {
        margin-top: -10px;
        margin-bottom: 35px;
    }
    @media #{$xxs-device} {
        display: block;
        text-align: center;
    }
    &:before {
        top: 50%;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #cacaca;
        position: absolute;
        transform: translateY(-50%);
        @media #{$xxs-device} {
            display: none;
        }
    }
    .section-title {
        margin-bottom: 0;
        margin-top: 0;
        background-color: $white;
        padding-right: 20px;
        position: relative;
    }
    .product-tab-menu {
        padding-right: 100px;
        padding-left: 20px;
        background-color: $white;
        position: relative;
        // responsive
        @media #{$sm-device} {
            padding-right: 0;
        }
        @media #{$xxs-device} {
            padding-left: 0;
            margin-top: 20px;
        }
    }
}

.section-title {
    margin-top: -4px;
    margin-bottom: 37px;

    .title {
        text-transform: capitalize;
        // responsive
        @media #{$sm-device} {
            font-size: 24px;
        }
    }

    .sub-title {
        font-size: 14px;
        padding-top: 6px;
        color: $paragraph;
    }
}

@keyframes shine {
    0% {
        background-position: -500%;
    }

    100% {
        background-position: 100%;
    }
}

.section-title-append {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 18px;
    line-height: 1;

    &:after {
        top: 50%;
        right: 0;
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        background-color: $bdr-color;
        transform: translateY(-50%);
        z-index: 1;
        margin-top: -3px;
    }

    h3 {
        display: inline-block;
        line-height: 1;
        text-transform: capitalize;
        background-color: $white;
        position: relative;
        z-index: 2;
        padding-right: 10px;
    }
}

/*------ section title end ------*/