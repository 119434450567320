/*----- breadcrumb style css start -----*/
.breadcrumb-area {
    background-color: #f5f5f5;
}
.breadcrumb-wrap {
    padding: 45px 0;
    text-align: center;
    // responsive
    @media #{$sm-device} {
        padding: 35px 0;
    }
    .breadcrumb {
        background: transparent;
        margin-bottom: 0;
        padding: 0;
        display: flex;
        align-items: center;
        .breadcrumb-item {
            line-height: 1;
            a {
                color: $black-soft;
                font-size: 14px;
                font-weight: 400;
                text-transform: capitalize;
                &:hover {
                    color: $theme-color;
                }
            }
            &:before {
                color: #7e7e7e;
                content: "/";
                font-size: 12px;
                margin: 0 5px;
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
            &.active {
                color: $theme-color;
                font-size: 14px;
                font-weight: 400;
                margin-top: 2px;
                text-transform: capitalize;
            }
        }
    }
}
/*----- breadcrumb style css end -----*/