/*------- common style css start -------*/
.bg-gray {
    background-color: #f3f5f6;
}
.black-bg {
    background-color: #222222;
}

/*-------- Custom Checkbox Style start --------*/
.custom-checkbox,
.custom-radio {

    &.custom-control {
        min-height: auto;
    }

    label,
    .custom-control-label {
        font-size: 14px;
        line-height: 1;
        padding-left: 10px;
        font-weight: 400;
        display: block;
        cursor: pointer;

        &:before {
            background-color: transparent;
            border: 1px solid #ddd;
            border-radius: 0;
            height: 15px;
            top: 0;
            width: 15px;
        }

        &:after {
            background-size: 10px 10px;
            height: 15px;
            top: 0;
        }

        a {
            color: $black-soft;
            font-weight: 500;

            &:hover {
                color: $theme-color;
            }
        }
    }

    input,
    .custom-control-input {
        outline: none;

        &:focus {

            ~.custom-control-label,
            label {
                &:before {
                    box-shadow: none;
                }
            }
        }

        &:checked {

            ~.custom-control-label,
            label {
                &:before {
                    background-color: $theme-color;
                    border-color: $theme-color;
                }
            }
        }
    }
}
/*-------- Custom Checkbox Style end --------*/

/*------- Custom Radio Button Style start -------*/
.custom-radio {
    label,
    .custom-control-label {
        &:before {
            border-radius: 50%;
            height: 14px;
            width: 14px;
        }

        &::after {
            background-size: 10px 10px;
            left: -22px;
            top: 2px;
            height: 10px;
            width: 10px;
        }
    }
}
/*------- Custom Radio Button Style end -------*/

/*----- product tab menu start -----*/
.featured-area {
    @media #{$sm-device} {
        padding-top: 68px;
    }
}
.product-tab-menu {
    ul {
        li {
            margin-right: 35px;
            // responsive
            @media #{$xxs-device} {
                margin-right: 15px;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.2;
                color: $paragraph-light; 
                &:hover,
                &.active {
                    color: $black-soft;
                }
            }
        }
    }
}

// tab animation
.tab-content {
    .tab-pane {
        &.show {
            &.active {
                .product-item {
                    animation-name: zoomIn;
                    animation-duration: 1s;
                }
            }
        }
    } 
}

/*----- product tab menu start -----*/

/*----- brand logo style start -----*/
.brand-logo-carousel {
    padding: 30px 0;
    border-top: 1px solid $bdr-color;
    border-bottom: 1px solid $bdr-color;
}
.brand-item {
    img {
        margin: auto;
    }
}
/*----- brand logo style end -----*/

/*------ special product style start ------*/
.special-product-thumb {
    @media #{$md-device} {
        margin-top: 3px;
    }
}
.special {
    &-content {
        padding-right: 40px;
        // responsive
        @media #{$md-device, $sm-device} {
            padding-top: 43px;
            padding-right: 0;
        }
        .price {
            &-old {
                font-size: 18px;
                color: $paragraph;
                padding-right: 5px;
            }
            &-regular {
                font-size: 24px;
                color: $theme-color;
            }
        }
        p {
            color: $paragraph-light;
            padding-top: 22px;
            margin-bottom: 35px;
            // responsive
            @media #{$sm-device} {
                margin-bottom: 25px;
            }
        }
    }
    &-title {
        font-weight: 700;
        padding-bottom: 22px;
        // responsive
        @media #{$sm-device} {
            font-size: 24px;
            font-weight: 600;
            padding-bottom: 10px;
        }
    }
}
/*------ special product style end ------*/

/*------- common style css end -------*/