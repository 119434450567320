/*------ banner statistics style start ------*/
/* 1.0 banner statistics */

.banner {
    &-statistics {
        overflow: hidden;
        img {
            width: 100%;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}

/*------ banner statistics style end ------*/