/*------------------------------------------------------------------
Table of content
-------------------
1. Start Header Area
2. slider area start
3. features area css start
4. hot deals area css start
5. product
6. categories area
7. testimonial
8. blog
9. shop grid view
10. shop list view
11. product details
12. checkout
13. cart
14. wishlist
15. my account
16. compare
17. login register
18. contact us
19. banner
20. modal
21. sidebar
22. footer
-------------------------------------------------------------------*/