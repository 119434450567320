/*------ Start Compare Page Wrapper -----*/
.compare-table {
    & .table {
        & tbody {
            & tr {
                & td {
                    text-align: center;
                    padding: 20px 20px;
                    vertical-align: middle;
                    border-color: #ccc;

                    &.first-column {
                        min-width: 175px;
                        font-weight: 500;
                        color: $black-soft;
                        margin: 0;

                        @media #{$sm-device} {
                            min-width: 115px;
                        }
                    }

                    &.product-image-title {
                        min-width: 310px;
                        vertical-align: bottom;

                        @media #{$sm-device} {
                            min-width: 220px;
                        }

                        & .image {
                            clear: both;
                            width: 100%;
                            margin-bottom: 15px;
                            display: block;
                        }

                        & .category {
                            float: left;
                            clear: both;
                            font-size: 14px;
                            color: $theme-color;
                        }

                        & .title {
                            float: left;
                            clear: both;
                            color: $black-soft;
                            text-transform: capitalize;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }

                    &.pro-desc {
                        & p {
                            color: $paragraph;
                            text-align: left;
                            margin: 0;
                        }
                    }

                    &.pro-price,
                    &.pro-color,
                    &.pro-stock {
                        font-weight: 400;
                    }

                    &.pro-remove {
                        & button {
                            border: none;
                            background-color: transparent;
                            padding: 0;
                            cursor: pointer;
                            transition: 0.4s;

                            &:hover {
                                color: $red;
                            }

                            & i {
                                font-size: 20px;
                            }
                        }
                    }

                    &.pro-ratting {
                        & i {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
}
/*------ end Compare Page Wrapper -----*/