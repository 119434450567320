/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
    height: 680px;
    display: flex;
    align-items: center;
    // responsive
    @media #{$md-device, $sm-device} {
        height: 400px;
    }
    @media #{$xs-device} {
        height: 350px;
    }
}
.hero-slider-content {
    // responsive
    @media #{$xs-device} {
        padding-right: 140px;
    }
    @media #{$xxs-device} {
        padding-right: 0;
    }
    .slide {
        &-title{
            font-size: 60px;
            font-weight: normal;
            color: $white;
            span {
                display: block;
            }
            // responsive
            @media #{$md-device} {
                font-size: 50px;
            }
            @media #{$sm-device} {
                font-size: 40px;
                line-height: 1.2;
            }
            @media #{$xs-device} {
                font-size: 30px;
            }
        }  
        &-desc {
            color: $white;
            font-weight: 400;
            line-height: 1.3;
            padding-top: 20px;
            font-family: $font-family-base;
            @media #{$xs-device} {
                padding-top: 10px;
            }
        }
    }
}

.hero-overlay {
    position: relative; 
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: rgba($black, 0.5);
        display: none;
        // responsive
        @media #{$sm-device} {
            display: block;
        }
    }
}
/*------ hero slider area css end ------*/

