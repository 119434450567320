/*----- Google map area start -----*/
#google-map {
    height: 500px;
    width: 100%;
    // responsive
    @media #{$md-device} {
        height: 400px;
    }
    @media #{$sm-device} {
        height: 350px;
    }
}
/*----- Google map area end -----*/

/*------ contact form area start -------*/
.contact-area {
    // responsive
    @media #{$md-device, $sm-device} {
        margin-bottom: -8px;
    }
}
.contact-message {
    h2 {
        color: $black-soft;
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        padding-bottom: 28px;
        text-transform: capitalize;
        // responsive
        @media #{$xs-device} {
            font-size: 18px;
            padding-bottom: 15px;
        }
    }
    form {
        input, textarea {
            width: 100%;
            border: none;
            padding: 10px 10px;
            border-bottom: 3px solid transparent;
            background-color: #f7f7f7;
            margin-bottom: 30px;
        }
        textarea  {
            height: 150px;
        }
    }
}
/*------ contact form area start -------*/

/*------ contact info area start -------*/
.contact-info {
    height: 100%;
    // responsive
    @media #{$md-device} {
        margin-top: 95px;
    }
    @media #{$sm-device} {
        margin-top: 60px;
    }
    p {
        padding-bottom: 30px;
        // responsive
        @media #{$md-device} {
            padding-bottom: 10px;
        }
    }
    ul {
        li {
            border-bottom: 1px solid $bdr-color;
            padding-bottom: 15px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            // responsive
            @media #{$xxs-device} {
                align-items: baseline;
            }
            &:last-child {
                border-bottom: none;
            }
            i {
                font-size: 18px;
                padding-right: 10px;
            }
        }
    }
    .working-time {
        h6 {
            padding-bottom: 5px;
        }
        p {
            color: $paragraph;
            padding-bottom: 0;
            span {
                padding-right: 10px;
            }
        }
    }
}
.contact-title {
    line-height: 1;
    padding-bottom: 22px;
    // responsive
    @media #{$xs-device} {
        font-size: 20px;
        padding-bottom: 8px;
    }
}
/*------ contact info area end -------*/